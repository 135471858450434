<template>
  <a-drawer
    title="新建配置项"
    :maskClosable="false"
    width=750
    placement="right"
    :closable="false"
    @close="onClose"
    :visible="bizConfigAddVisiable"
    style="height: calc(100% - 55px);overflow: auto;padding-bottom: 53px;">
    <a-form :form="form">
      <a-form-item label='KEY' v-bind="formItemLayout">
          <a-input v-decorator="['kkey',
                   {rules: [
                    { required: true, message: '不能为空'}
                  ]}]" />
      </a-form-item>
      <a-form-item label='VALUE' v-bind="formItemLayout">
          <a-input v-decorator="['valuee',
                   {rules: [
                    { required: true, message: '不能为空'}
                  ]}]" />
      </a-form-item>
      <a-form-item label='备注' v-bind="formItemLayout">
          <a-input v-decorator="['note']" />
      </a-form-item>
    </a-form>
    <div class="drawer-bootom-button">
      <!-- <a-popconfirm title="确定放弃编辑？" @confirm="onClose" okText="确定" cancelText="取消">
        <a-button style="margin-right: .8rem">取消</a-button>
      </a-popconfirm> -->
			<a-button @click="onClose" style="margin-right: .8rem">取消</a-button>
      <a-button @click="handleSubmit" type="primary" :loading="loading">提交</a-button>
    </div>
  </a-drawer>
</template>

<script>
import moment from 'moment'
const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 18 }
}

export default {
  name: 'bizConfigAdd',
  components: {},
  props: {
    bizConfigAddVisiable: {
      default: false
    }
  },
  data () {
    return {
      loading: false,
      formItemLayout,
      form: this.$form.createForm(this),
      taskTypeOptions: [],
      value: [],
      engineers: [],
      bizConfig: {}
    }
  },
  created() {
  },
  computed: {
    bizType() {
      if(this.$route.path.indexOf('/1') > 0) {
        return 1
      }
      if(this.$route.path.indexOf('/2') > 0) {
        return 2
      }
      return undefined
    }
  },
  methods: {
    reset () {
      this.loading = false
      this.task = {}
      this.form.resetFields()
    },
    onClose () {
      this.reset()
      this.$emit('close')
    },
    handleSubmit () {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.setBizConfigFields()
          this.$post('biz-config', {
            ...this.bizConfig, type: this.bizType
          }).then(() => {
            this.reset()
            this.$emit('success')
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },
    setBizConfigFields () {
      let values = this.form.getFieldsValue(['kkey', 'valuee', 'note'])
      if (typeof values !== 'undefined') {
        Object.keys(values).forEach(_key => { this.bizConfig[_key] = values[_key] })
      }
    }
  }
}
</script>
