import { render, staticRenderFns } from "./bizConfig.vue?vue&type=template&id=b13bd380&scoped=true&"
import script from "./bizConfig.vue?vue&type=script&lang=js&"
export * from "./bizConfig.vue?vue&type=script&lang=js&"
import style0 from "./bizConfig.vue?vue&type=style&index=0&id=b13bd380&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b13bd380",
  null
  
)

export default component.exports