<template>
  <a-drawer
    title="修改配置项"
    :maskClosable="false"
    width=750
    placement="right"
    :closable="false"
    @close="onClose"
    :visible="bizConfigEditVisiable"
    style="height: calc(100% - 55px);overflow: auto;padding-bottom: 53px;">
    <a-form :form="form">
      <a-form-item label='KEY' v-bind="formItemLayout">
          <a-input disabled v-decorator="['kkey',
                   {rules: [
                    { required: true, message: '不能为空'}
                  ]}]" />
      </a-form-item>
      <a-form-item label='VALUE' v-bind="formItemLayout">
          <a-input v-decorator="['valuee',
                   {rules: [
                    { required: true, message: '不能为空'}
                  ]}]" />
      </a-form-item>
      <a-form-item label='备注' v-bind="formItemLayout">
          <a-input v-decorator="['note']" />
      </a-form-item>
    </a-form>
    <div class="drawer-bootom-button">
      <!-- <a-popconfirm title="确定放弃编辑？" @confirm="onClose" okText="确定" cancelText="取消">
        <a-button style="margin-right: .8rem">取消</a-button>
      </a-popconfirm> -->
			<a-button @click="onClose" style="margin-right: .8rem">取消</a-button>
      <a-button @click="handleSubmit" type="primary" :loading="loading">提交</a-button>
    </div>
  </a-drawer>
</template>

<script>
const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 18 }
}

export default {
  name: 'bizConfigEdit',
  components: {},
  props: {
    bizConfigEditVisiable: {
      default: false
    }
  },
  data () {
    return {
      loading: false,
      formItemLayout,
      form: this.$form.createForm(this),
      engineers: [],
      bizConfig: {}
    }
  },
  created() {
  },
  computed: {
    bizType() {
      if(this.$route.path.indexOf('/1') > 0) {
        return 1
      }
      if(this.$route.path.indexOf('/2') > 0) {
        return 2
      }
      return undefined
    }
  },
  methods: {
    reset () {
      this.loading = false
      this.form.resetFields()
    },
    onClose () {
      this.reset()
      this.$emit('close')
    },
    setFormValues ({...bizConfig}) {
      let fields = ['kkey', 'valuee', 'note']
      Object.keys(bizConfig).forEach((key) => {
        if (fields.indexOf(key) !== -1) {
          this.form.getFieldDecorator(key)
          let obj = {}
          obj[key] = bizConfig[key]
          this.form.setFieldsValue(obj)
        }
      })
      this.bizConfig = bizConfig
    },
    handleSubmit () {
      this.form.validateFields((err, values) => {
        if (!err) {
          let bizConfig = this.form.getFieldsValue()
          bizConfig.bizConfigId = this.bizConfig.bizConfigId

          this.$put('biz-config', {
            ...bizConfig, type: this.bizType
          }).then(() => {
            this.reset()
            this.$emit('success')
          }).catch(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>
