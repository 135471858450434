<template>
  <a-card :bordered="false" class="card-area">
    <div :class="advanced ? 'search' : null">
      <a-form layout="horizontal">
        <div :class="advanced ? null: 'fold'">
          <a-row >
            <a-col :md="8" :sm="24" >
              <a-form-item
                label="KEY"
                :labelCol="{span: 5}"
                :wrapperCol="{span: 18, offset: 1}">
                <a-input v-model="queryParams.kkey" />
              </a-form-item>
            </a-col>
          </a-row>
        </div>
        <span style="float: right; margin-top: 3px;">
          <a-button type="primary" @click="search">查询</a-button>
        </span>
      </a-form>
    </div>
    <div>
      <div class="operator">
        <a-button v-hasPermission="['bizConfig:add']" type="primary" ghost @click="add">新增</a-button>
      </div>
      <!-- 表格区域 -->
      <a-table ref="TableInfo"
               :columns="columns"
               :rowKey="record => record.bizConfigId"
               :dataSource="dataSource"
               :pagination="pagination"
               :loading="loading"
               :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
               @change="handleTableChange"
               :scroll="{ x: 900 }">
        <template slot="operation" slot-scope="text, record">
          <a-icon v-hasPermission="['bizConfig:update']" type="setting" theme="twoTone" twoToneColor="#4a9ff5" @click="edit(record)" title="修改项目"></a-icon>
          <a-badge v-hasNoPermission="['bizConfig:update']" status="warning" text="无权限"></a-badge>
        </template>
      </a-table>
      <!-- 新增 -->
      <biz-config-add
        @close="handleBizConfigAddClose"
        @success="handleBizConfigAddSuccess"
        :bizConfigAddVisiable="bizConfigAddVisiable">
      </biz-config-add>
      <!-- 修改 -->
      <biz-config-edit
        ref="bizConfigEdit"
        @close="handleBizConfigEditClose"
        @success="handleBizConfigEditSuccess"
        :bizConfigEditVisiable="bizConfigEditVisiable">
      </biz-config-edit>
    </div>
  </a-card>
</template>
<script>
import bizConfigAdd from './bizConfigAdd'
import bizConfigEdit from './bizConfigEdit'
import RangeDate from '@/components/datetime/RangeDate'
import {mapState} from 'vuex'
import moment from 'moment'

export default {
  name: 'bizConfig',
  components: {RangeDate, bizConfigAdd, bizConfigEdit},
  data () {
    return {
      fileList: [],
      importData: [],
      times: '',
      errors: [],
      uploading: false,
      importResultVisible: false,
      advanced: false,
      dataSource: [],
      selectedRowKeys: [],
      paginationInfo: null,
      pagination: {
        pageSizeOptions: ['10', '20', '30', '40', '100'],
        defaultCurrent: 1,
        defaultPageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      queryParams: {},
      bizConfigAddVisiable: false,
      bizConfigEditVisiable: false,
      loading: false
    }
  },
  computed: {
    columns () {
      return [{
        title: 'KEY',
        dataIndex: 'kkey',
        width: 200
      }, {
        title: 'VALUE',
        dataIndex: 'valuee',
        customRender: (text, row, index) => {
          return row.type == 2 ? <img src={text} width="60px"/> : text;
        },
        width: 300
      }, {
        title: '备注',
        dataIndex: 'note',
        width: 250
      }, {
        title: '创建人',
        dataIndex: 'createUsername',
        width: 150
      }, {
        title: '创建时间',
        dataIndex: 'createTime',
        width: 150,
        customRender: (text, row, index) => {
          return moment(text).format('YYYY-MM-DD HH:mm:ss') 
        },
      }, {
        title: '修改人',
        dataIndex: 'modifyUsername',
        width: 150
      }, {
        title: '修改时间',
        dataIndex: 'updateTime',
        width: 150,
        customRender: (text, row, index) => {
          return moment(text).format('YYYY-MM-DD HH:mm:ss') 
        },
      }, {
        title: '操作',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' },
        fixed: 'right',
        width: 100
      }]
    }
  },
  mounted () {
      this.fetch()
  },
  watch: {
    $route() {
      this.fetch()
    }
  },
  methods: {
    moment,
    bizType() {
      if(this.$route.path.indexOf('/1') > 0) {
        return 1
      }
      if(this.$route.path.indexOf('/2') > 0) {
        return 2
      }
      return undefined
    },
    onSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    search () {
      this.selectData({
        ...this.queryParams, type: this.bizType()
      })
    },
    handleBizConfigAddSuccess () {
      this.bizConfigAddVisiable = false
      this.$message.success('新建配置项成功')
      this.search()
    },
    handleBizConfigAddClose () {
      this.bizConfigAddVisiable = false
    },
    add () {
      this.bizConfigAddVisiable = true
    },
    handleBizConfigEditSuccess () {
      this.bizConfigEditVisiable = false
      this.$message.success('修改配置项成功')
      this.search()
    },
    handleBizConfigEditClose () {
      this.bizConfigEditVisiable = false
    },
    edit (record) {
      this.$refs.bizConfigEdit.setFormValues(record)
      this.bizConfigEditVisiable = true
    },
    handleTableChange (pagination, filters, sorter) {
      this.paginationInfo = pagination
      this.fetch({
        ...this.queryParams
      })
    },
    fetch (params = {}) {
      this.loading = true
      if (this.paginationInfo) {
        // 如果分页信息不为空，则设置表格当前第几页，每页条数，并设置查询分页参数
        this.$refs.TableInfo.pagination.current = this.paginationInfo.current
        this.$refs.TableInfo.pagination.pageSize = this.paginationInfo.pageSize
        params.pageSize = this.paginationInfo.pageSize
        params.pageNum = this.paginationInfo.current
      } else {
        // 如果分页信息为空，则设置为默认值
        params.pageSize = this.pagination.defaultPageSize
        params.pageNum = this.pagination.defaultCurrent
      }
      this.$get('biz-config', {
        ...params, type: this.bizType()
      }).then((r) => {
        let data = r.data
        const pagination = { ...this.pagination }
        pagination.total = data.total
        this.loading = false
        this.dataSource = data.rows
        this.pagination = pagination
      })
    },
    selectData (params = {}) {
      this.loading = true
      // 如果分页信息为空，则设置为默认值
      this.$refs.TableInfo.pagination.current = this.pagination.defaultCurrent
      this.$refs.TableInfo.pagination.pageSize = this.pagination.defaultPageSize
      params.pageSize = this.pagination.defaultPageSize
      params.pageNum = this.pagination.defaultCurrent
      this.$get('biz-config', {
        ...params
      }).then((r) => {
        let data = r.data
        const pagination = { ...this.pagination }
        pagination.total = data.total
        this.loading = false
        this.dataSource = data.rows
        this.pagination = pagination
      })
    }
  },
}
</script>
<style lang="less" scoped>
  .test {
    color: #42b984;
    font-size: 1.1rem;
    font-weight: 600;
  }
</style>